.App {
  background-color: #f6f7fb;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.HomePage {
  background-color: #006fb2;
  height: 100vh;
  width: 100vw;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

a {
  color: #006fb2;
  font-size: 14px;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.link {
  color: #006fb2;
  display: block;
  font-size: 14px;
  font-weight: 800;
  line-height: 28px;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.flex-container {
  display: flex;
  align-items: center;
}

/* NAVIGACIJOS JUOSTA ------------------------------------------------------------ */

.logo img {
  height: 1.4rem;
}

.logo .logo__wrapper {
  padding: 2rem 0;
}

.logo > h1 {
  cursor: default;
  color: white;
}

.logout-button {
  text-align: left;
  position: relative;
  display: flex;
  align-items: center;
}

.triangle {
  width: 10px;
  margin-left: 15px;
}

.triangle::after {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;
  content: '';
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

/* FOOTER ------------------------------------------------------------------------- */

.app-footer {
  background-color: #eeeeee;
  flex-shrink: 0;
  width: 100%;
}

.footer-left {
  display: flex;
}

.footer-first-column {
  margin-right: 170px;
}

.logo-KM {
  margin-right: 70px;
}

.footer-link {
  color: #006fb2;
  display: block;
  font-size: 14px;
  font-weight: 800;
  line-height: 28px;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

/* PUSLAPIO APACIOJE ACTION MYGTUKU KONTEINERIO SESELIS --------------------------- */

.action-row-top-box-shadow {
  background-color: white;
  box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.1);
}

/* DUOMENU LENTELIU PUSLAPIS  ---------------------------------------------------- */

.contentWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.data-page {
  padding: 40px 0px;
  flex: 1 0 auto;
}

.named-data-table {
  padding: 25px 0px 30px;
}
.table-title {
  margin-bottom: 30px;
}

.data-table {
  border-radius: 10px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
}

.CustomizationBar {
  border: 0.5px solid #dddddd;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: none;
  position: relative;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: 10;
}

.TableStatusFilters {
  /* height: 48px; */
  border-right: 0.5px solid #dddddd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
}

.CustomFilter {
  background-color: #dbedfc;
}

.TablePagination {
  background-color: rgb(255, 255, 255);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #dddddd;
  border-top: none;
  padding: 3px 0px;
  width: 100%;
}

.pagination-item {
  display: flex;
  align-items: center;
  margin: auto 15px;
}

.align-extended-row-data {
  width: 120px;
  display: inline-block;
  text-align: right;
  font-weight: 600;
  margin-right: 10px;
}

.row-action-button {
  padding: 0;
  border-color: #a80808;
  background-color: 'red';
  color: #637082;
}

.column-of-buttons {
  width: 155px;
  box-sizing: border-box;
}

.MuiTableCell-root.TableCellSortable {
  cursor: pointer;
}

.MuiTableCell-root.TableCellSortable:hover {
  background-color: #dbedfc;
}

.MuiTableCell-root.TableCellSortable:hover .MuiTableSortLabel-root,
.MuiTableCell-root.TableCellSortable[aria-sort] .MuiTableSortLabel-root.MuiTableSortLabel-active,
.MuiTableCell-root.TableCellSortable:hover .MuiTableSortLabel-root .MuiTypography-root,
.MuiTableCell-root.TableCellSortable[aria-sort] .MuiTableSortLabel-root .MuiTypography-root,
.MuiTableCell-root.TableCellSortable:hover
  .MuiTableSortLabel-root.MuiTableSortLabel-active
  .MuiTableSortLabel-icon,
.MuiTableCell-root.TableCellSortable[aria-sort]
  .MuiTableSortLabel-root.MuiTableSortLabel-active
  .MuiTableSortLabel-icon {
  color: #006fb2;
}

.MuiGrid-grid-xs-4 {
  word-wrap: break-word;
  hyphens: auto;
}
/* ISSOKANTYS LANGAI  ------------------------------------------------------------ */

.dialog-title {
  padding: 20px 35px 10px 35px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dialog-main-content {
  padding: 10px 35px 10px 35px;
}
.dialog-actions {
  background-color: #f3f3f3;
  padding: 10px 35px;
  display: flex;
  justify-content: flex-end;
}

.dialog-main-content-box {
  display: flex;
}

/* INPUT VIDINIU CLEAR MYGTUKU PASALINIMAS  -------------------------------------------------------------------- */

*::-ms-clear {
  display: none;
}

/* AUTOCOMPLETE DROPDOWN plotis prisitaiko prie teksto kiekio eilutėje */
.MuiAutocomplete-popper {
  width: auto !important;
  max-width: 60%;
}

.MuiAutocomplete-tag {
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.8125rem;
  padding: 3px 7px;
}

.MuiAutocomplete-tag,
.MuiAutocomplete-tag.MuiChip-root {
  background-color: #f1f1f2;
  border-radius: 5px;
}

/* MYGTUKAI  -------------------------------------------------------------------- */

.MuiButtonBase-root.MuiButton-containedPrimary.Mui-disabled {
  color: #ffffff;
  background-color: #949494;
}

/* DALYVIAI --------------------------------------------------------------------- */
/* DALYVIAI: KONKREČIOS DATOS LENTELĖ -------------------------------------------- */

.margin-right-25 {
  margin-right: 25px;
}

.color-bar-prev-foll-date {
  display: flex;
  min-height: 26px;
  position: absolute;
  transform: translateY(-50%);
  box-sizing: border-box;
  padding: 3px 0;
  border-spacing: 0;
}

.color-bar-selected-date {
  display: flex;
  min-height: 26px;
  position: absolute;
  transform: translateY(-50%);
  box-sizing: border-box;
  padding: 0;
  border-spacing: 0;
  align-items: center;
}

.color-row-triangle {
  display: inline-block;
  position: absolute;
  min-height: 18px;
  min-width: 18px;
  overflow: hidden;
  border-radius: 0 0 0 5px;
  margin: 0px;
  transform: translateY(-50%) rotate(45deg);
  box-sizing: border-box;
  left: 40.5%;
}

.triangle-button-width {
  width: 25px;
}

.shareholder-expanded-row-container {
  padding-left: 50px;
}

/* DALYVIAI: PAGRINDINĖ LENTELĖ  -------------------------------------------------------------------- */

.shareholders .shareholders-table-wrapper {
  position: relative;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
}

.table-scroller {
  position: relative;
  overflow-y: hidden;
  border-radius: 10px;
}

.shareholders .CustomizationBar {
  border: none;
}

.header-sticky-col {
  position: sticky;
  position: -webkit-sticky;
  z-index: 5;
  background-color: #f8f8f8;
}

.header-level-column.first-col {
  width: 64px;
  box-sizing: border-box;
  min-width: 64px;
  max-width: 64px;
  left: 0;
}
.header-sticky-col.second-col {
  left: 64px;
  box-sizing: border-box;
  min-width: 318px;
}

.sticky-col {
  position: sticky;
  position: -webkit-sticky;
  background-color: white;
  z-index: 1250;
  box-shadow: 0px 1px 0 #dddddd;
}
@media (max-width: 959px) {
  .sticky-col {
    position: static;
  }
  .header-sticky-col {
    position: static;
  }
}
.sticky-col.first-col {
  width: 64px;
  box-sizing: border-box;
  min-width: 64px;
  max-width: 64px;
  left: 0px;
  padding: 0;
  border-right: none;
  border-left: none;
}

.sticky-col.second-col {
  left: 64px;
  box-sizing: border-box;
  padding: 0;
  border-right: none;
  border-left: none;
  width: 550px;
  min-width: 550px;
}

.header-share-column.header-sticky-col,
.header-level-column.header-sticky-col {
  padding: 0;
  border: none;
}

.fake-header-cell {
  height: 88px;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #dddddd;
}

.fake-cell {
  padding: 15px 15px 15px 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  min-height: 70px;
}

.fake-cell.level-cell {
  justify-content: center;
}

.fake-header-cell.shareholder-cell,
.fake-cell.shareholder-cell {
  padding-left: 8px;
  border-right: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  justify-content: flex-start;
}

.fake-header-cell.shareholder-cell {
  padding-left: 15px;
}

.fake-cell.first-shareholder {
  justify-content: space-between;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
}

.shareholders-table .shareholders-table-row {
  border-left: none;
  border-right: none;
}

.data-column {
  width: 90px;
  min-width: 90px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  align-items: center;
  position: relative;
}

.data-column.cell-selected {
  box-shadow: inset 0px 0px 0px 1px #006fb2;
  z-index: 10;
}

.date-details {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  border-top-left-radius: 0px;
  min-width: 240px;
  border: 1px solid #006fb2;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  font-size: 0.75rem;
}

.shareholders-table .last-column {
  min-width: 200px;
  box-sizing: border-box;
  border-right: none;
  position: relative;
}

.name-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.basic-color-row-bar {
  display: inline-block;
  min-height: 24px;
  position: absolute;
  transform: translateY(-50%);
  left: 50%;
  box-sizing: border-box;
  font-size: 0.75rem;
  min-width: 10px;
  height: 24px;
  padding: 3px 10px;
  z-index: 1;
}

/* spalvota akciju juostele */
.color-row-bar {
  position: absolute;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  box-sizing: border-box;
  padding: 3px 10px;
  font-size: 0.75rem;
  min-width: 10px;
  height: 24px;
  font-weight: bold;
  z-index: 9;
  color: #ffffff;
}

.base-bg-color {
  background-color: rgba(229, 229, 229, 0.8);
  border: 1px solid #707070;
}

/* sutikrinti ir suvienodinti su konkrecio datos lentele */
.sh-additional-info {
  font-size: 0.75rem;
  display: block;
  color: #aeaeae;
  text-align: left;
}

/* arrow-left jei nera pradzios datos */
.row-bar-arrow {
  display: inline-block;
  position: absolute;
  min-height: 17px;
  min-width: 16.9px;
  overflow: hidden;
  margin: 0px;
  transform: translateY(-50%) rotate(45deg);
  box-sizing: border-box;
  left: -7px;
  top: 11px;
  z-index: 5;
  border-bottom-left-radius: 3px;
}

.triangle-main-table {
  left: 33%;
  min-height: 17px;
  min-width: 17px;
}

/* Dalyvių išskleidžiama eilutė */

.sticky-col.extended-cell {
  position: sticky;
  position: -webkit-sticky;
  z-index: 10;
  left: 0;
  width: 400px;
  border-right: none;
  font-size: 0.85rem;
  padding-left: 64px;
}

.details-block {
  margin-bottom: 10px;
  font-size: 0.85rem;
}

.details-block:last-child {
  margin-bottom: 0px;
}

.details-block-row {
  display: flex;
  align-items: center;
}

.extended-row-label {
  margin-right: 10px;
}

.extended-row-date {
  margin-right: 5px;
  display: inline-block;
  font-weight: 500;
}

.details-block-regular-font {
  font-weight: 500;
}

.expand-details-button-placeholder {
  width: 24px;
  height: auto;
  flex-shrink: 0;
}

/* Laiko ir datos Pickeriai */

.MuiPickersTimePickerToolbar-hourMinuteLabel {
  align-items: center !important;
  justify-content: center !important;
}

.MuiFormControlLabel-root {
  margin-left: 0 !important;
}

@media (max-width: 959px) {
  .CustomizationBar {
    padding: 10px;
    width: unset;
  }

  .TableStatusFilters {
    border: none;
    margin: 0;
  }
}

@media (max-width: 599px) {
  .footer-first-column {
    margin-right: 100px;
  }

  .dialog-title {
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
  .MuiButtonBase-root.MuiButton-containedPrimary {
    block-size: fit-content;
  }
  .dialog-main-content-box {
    flex-direction: column-reverse;
  }

  .dialog-actions {
    flex-wrap: wrap;
  }
}
